.type1 {
  width: 13rem;
  height: 100%;
  display: block;
  .btn {
    width: 100%;
    cursor: pointer;

    .code_btn {
      height: 48px;
      line-height: 48px;
      position: relative;

      .get_code {
        transition: margin 0.2s, padding 0.2s;
        z-index: 12;
        display: block;
        padding: 0 0.5rem 0 1rem;
        max-width: 100%;
        color: #fff;
        border: 0;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
        user-select: none;
        cursor: pointer;
        position: relative;
        background: #a243b5;
        border-radius: 24px;
        overflow: hidden;
        &:hover{
          color: rgba(255, 255, 255, 0.7);
        }
        &::before,
        &::after {
          position: absolute;
          width: 3rem;
          height: 2rem;
          opacity: 1;
          background-color: rgb(209 213 219/1);
          content: '';
          transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1)
            scaleY(1);
        }

        &::before {
          top: -0.75rem;
          right: -1.25rem;
          z-index: 10;
          rotate: 0;
        }

        &::after {
          width: 3rem;
          height: 3rem;
          top: -1rem;
          right: -1rem;
          background-color: rgba(229, 231, 235, 0.3);
          rotate: 45deg;
          border-radius: 9999px;
        }

        // &::before {
        //   content: '';
        //   display: block;
        //   width: 29px;
        //   height: 51px;
        //   background: url('../../../assets/image_red.png') no-repeat;
        //   background-size: contain;
        //   position: absolute;
        //   z-index: 12;
        //   right: -20px;
        // }

        .pc {
          font-weight: 900;
        }

        .mobile {
          display: none;
          font-weight: 900;
        }
      }

      .hidden_code {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        color: #40423f;
        border: 2px dashed #ddd;
        // border-radius: 2.5rem;
        background-color: #f5f5f5;
        padding-right: 10px;
        font-weight: 700;
        text-align: right;
        word-wrap: break-word;
        font-size: 1.3rem;
        box-sizing: border-box;
        letter-spacing: 1px;
        border-radius: 24px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .type1 {
    width: auto;
    transform: unset;

    .btn {
      .code_btn {
        height: 40px;
        line-height: 40px;

        .get_code {
          .pc {
            display: none;
          }

          .mobile {
            display: block;
            font-weight: 900;
          }
        }

        .hidden_code {
          border: 1px dashed #ddd;
        }
      }
    }
  }
}
